import React, { useEffect, useMemo, useState, useRef, useCallback, Fragment } from "react";
import {
  Button,
  Dropdown,
  Form,
  Input,
  Modal,
  Popup,
  Segment,
  Icon,
  Grid,
  Divider,
} from "semantic-ui-react";

import moment from "moment";

import { DateTextBox } from "react-lib/apps/common";
import SnackMessage from "react-lib/apps/common/SnackMessage";
import ButtonLoadCheck from "react-lib/appcon/common/ButtonLoadCheck";
import SearchBox from "react-lib/appcon/common/SearchBox";
import CardZoneLog from "../common/CardZoneLog";
import CardPatientSearchBox from "../TPD/CardPatientSearchBox";
import CardORRequest from "./CardORRequest";
import CardORQueueUX from "./CardORQueueUX";
import { CARD_OR_QUEUE_ID } from "./sequence/ORQueue";
import ActionMenuColumn from "react-lib/apps/HISV3/DPI/ActionMenuColumn";

import { formatDate } from "react-lib/utils/dateUtils";

const OPERATING_ORDER_ACTION = {
  CONFIRM: "CONFIRM",
  SCHEDULE: "SCHEDULE",
  CANCEL_SCHEDULE: "CANCEL_SCHEDULE",
  CHECK_IN: "CHECK_IN",
  CANCEL_CHECK_IN: "CANCEL_CHECK_IN",
  ZONE: "ZONE",
  ZONELOG: "ZONELOG",
  ZONE_HOLDING_ROOM: "ZONE_HOLDING_ROOM",
  ZONE_OPERATING_ROOM: "ZONE_OPERATING_ROOM",
  ZONE_PACU: "ZONE_PACU",
};

const MAX_LENGTH = 45;

// ================================================== ActionMenuColumn
// export const ActionMenuColumn = (props: any) => {
//   const [openPopup, setOpenPopup] = useState<boolean>(false);

//   const handleCallback = (event: any, data: any) => {
//     event.stopPropagation();

//     props.onCallback(props.order, data.name);
//   };

//   return (
//     <>
//       {
//         <div style={{ justifyContent: "center" }}>
//           <Popup
//             open={openPopup}
//             style={{ widdth: "180px" }}
//             content={
//               <div style={{ width: "150px" }}>
//                 <ButtonLoadCheck
//                   // function
//                   setProp={props.setProp}
//                   onClick={handleCallback}
//                   // data
//                   paramKey={`${CARD_OR_QUEUE_ID}_${OPERATING_ORDER_ACTION.CHECK_IN}`}
//                   buttonLoadCheck={
//                     props.buttonLoadCheck?.[
//                       `${CARD_OR_QUEUE_ID}_${OPERATING_ORDER_ACTION.CHECK_IN}`
//                     ]
//                   }
//                   // config
//                   color="green"
//                   fluid={true}
//                   name={OPERATING_ORDER_ACTION.CHECK_IN}
//                   style={{
//                     marginTop: "10px",
//                     display: props.order?.allowed_action?.includes(
//                       OPERATING_ORDER_ACTION.CHECK_IN
//                     )
//                       ? ""
//                       : "none",
//                   }}
//                   title="รับเข้า"
//                 />
//                 <ButtonLoadCheck
//                   // function
//                   setProp={props.setProp}
//                   onClick={handleCallback}
//                   // data
//                   paramKey={`${CARD_OR_QUEUE_ID}_${OPERATING_ORDER_ACTION.CANCEL_CHECK_IN}`}
//                   buttonLoadCheck={
//                     props.buttonLoadCheck?.[
//                       `${CARD_OR_QUEUE_ID}_${OPERATING_ORDER_ACTION.CANCEL_CHECK_IN}`
//                     ]
//                   }
//                   // config
//                   color="red"
//                   fluid={true}
//                   name={OPERATING_ORDER_ACTION.CANCEL_CHECK_IN}
//                   style={{
//                     marginTop: "10px",
//                     display: props.order?.allowed_action?.includes(
//                       OPERATING_ORDER_ACTION.CANCEL_CHECK_IN
//                     )
//                       ? ""
//                       : "none",
//                   }}
//                   title="ยกเลิกรับเข้า"
//                 />
//                 <ButtonLoadCheck
//                   // function
//                   setProp={props.setProp}
//                   onClick={handleCallback}
//                   // data
//                   paramKey={`${CARD_OR_QUEUE_ID}_${OPERATING_ORDER_ACTION.SCHEDULE}`}
//                   buttonLoadCheck={
//                     props.buttonLoadCheck?.[
//                       `${CARD_OR_QUEUE_ID}_${OPERATING_ORDER_ACTION.SCHEDULE}`
//                     ]
//                   }
//                   // config
//                   color="blue"
//                   fluid={true}
//                   name={OPERATING_ORDER_ACTION.SCHEDULE}
//                   style={{
//                     marginTop: "10px",
//                     display: props.order?.allowed_action?.includes(
//                       OPERATING_ORDER_ACTION.SCHEDULE
//                     )
//                       ? ""
//                       : "none",
//                   }}
//                   title="Schedule"
//                 />
//                 <ButtonLoadCheck
//                   // function
//                   setProp={props.setProp}
//                   onClick={handleCallback}
//                   // data
//                   paramKey={`${CARD_OR_QUEUE_ID}_${OPERATING_ORDER_ACTION.CANCEL_SCHEDULE}`}
//                   buttonLoadCheck={
//                     props.buttonLoadCheck?.[
//                       `${CARD_OR_QUEUE_ID}_${OPERATING_ORDER_ACTION.CANCEL_SCHEDULE}`
//                     ]
//                   }
//                   // config
//                   color="red"
//                   fluid={true}
//                   name={OPERATING_ORDER_ACTION.CANCEL_SCHEDULE}
//                   style={{
//                     marginTop: "10px",
//                     display: props.order?.allowed_action?.includes(
//                       OPERATING_ORDER_ACTION.CANCEL_SCHEDULE
//                     )
//                       ? ""
//                       : "none",
//                   }}
//                   title="Cancel Schedule"
//                 />
//                 <Button
//                   fluid
//                   color="purple"
//                   style={{
//                     marginTop: "10px",
//                     display: props.order?.allowed_action?.some((r: string) =>
//                       [
//                         OPERATING_ORDER_ACTION.ZONE_HOLDING_ROOM,
//                         OPERATING_ORDER_ACTION.ZONE_OPERATING_ROOM,
//                         OPERATING_ORDER_ACTION.ZONE_PACU,
//                       ].includes(r)
//                     )
//                       ? ""
//                       : "none",
//                   }}
//                   name={OPERATING_ORDER_ACTION.ZONE}
//                   onClick={handleCallback}
//                 >
//                   รับเข้า Zone
//                 </Button>

//                 <Button
//                   fluid
//                   color="yellow"
//                   style={{ marginTop: "10px" }}
//                   name={OPERATING_ORDER_ACTION.CONFIRM}
//                   onClick={(event: any, data: any) => {
//                     event.stopPropagation();

//                     props.onCallback(props.order, data.name);

//                     setOpenPopup(false);
//                   }}
//                 >
//                   รายละเอียด
//                 </Button>
//                 <Button
//                   fluid
//                   color="grey"
//                   style={{ marginTop: "10px" }}
//                   name={OPERATING_ORDER_ACTION.ZONELOG}
//                   onClick={(event: any, data: any) => {
//                     event.stopPropagation();

//                     props.onCallback(props.order, data.name);

//                     setOpenPopup(false);
//                   }}
//                 >
//                   Zone Log
//                 </Button>
//               </div>
//             }
//             position="bottom right"
//             onOpen={() => setOpenPopup(true)}
//             onClose={() => setOpenPopup(false)}
//             on="click"
//             trigger={
//               <Button
//                 icon="list layout"
//                 size="small"
//                 onDoubleClick={(e: any) => e.stopPropagation()}
//                 onClick={(e: any) => e.stopPropagation()}
//               ></Button>
//             }
//           />
//         </div>
//       }
//     </>
//   );
// };

// ================================================== CardORQueue
type CardORQueueProps = {
  // function
  onEvent: any;
  setProp: any;
  forward: any;

  // CommonInterface
  errorMessage?: any;
  successMessage?: any;
  buttonLoadCheck?: any;

  // seq
  runSequence: any;
  ORQueueSequence?: any;
  ORRequestSequence?: any;

  // controller
  drugOrderQueueController?: any;

  // SearchBox
  searchedItemList?: any[];
  searchedItemListWithKey?: any;

  // options
  masterOptions?: any;
  masterData?: any;

  // data
  selectedOrOrder?: any;
};

const CardORQueueInitial: CardORQueueProps = {
  // funtion
  onEvent: () => null,
  setProp: () => null,
  forward: () => null,

  // CommonInterface
  errorMessage: {},
  successMessage: {},
  buttonLoadCheck: null,

  // seq
  runSequence: null,
  ORQueueSequence: {},
  ORRequestSequence: {},

  // SearchBoxDropdown
  searchedItemList: [],
  searchedItemListWithKey: {},

  // options
  masterOptions: {},
  masterData: {},

  // data
  selectedOrOrder: {},
};

const CardORQueue = (props: any) => {
  const [openModORRequest, setOpenModORRequest] = useState<any>(null);
  const [modZoneLog, setModZoneLog] = useState<any>({
    open: false,
    order: null,
  });
  const [modConfirmData, setModConfirmData] = useState<any>({
    open: false,
    action: "",
    title: "",
    reason: "",
    order: null,
  });
  const [modChangeZone, setModChangeZone] = useState<any>({
    open: false,
    action: "",
    zone: "",
    location: "",
    room: "",
    order: null,
  });

  const touchCountRef = useRef(0);
  const touchTimerRef = useRef<any>(null);

  useEffect(() => {
    props.runSequence({
      sequence: "ORQueue",
      restart: true,
      is_drug_queue: props.config?.showFilterDrugQueue || false,
      is_ward_queue: props.config?.showFilterWardQueue || false,
    });
  }, []);

  useEffect(() => {
    if (
      ["ERROR", "SUCCESS"].includes(
        props.buttonLoadCheck?.[
          `${CARD_OR_QUEUE_ID}_${OPERATING_ORDER_ACTION.SCHEDULE}`
        ]
      ) ||
      ["ERROR", "SUCCESS"].includes(
        props.buttonLoadCheck?.[
          `${CARD_OR_QUEUE_ID}_${OPERATING_ORDER_ACTION.CANCEL_SCHEDULE}`
        ]
      ) ||
      ["ERROR", "SUCCESS"].includes(
        props.buttonLoadCheck?.[
          `${CARD_OR_QUEUE_ID}_${OPERATING_ORDER_ACTION.CHECK_IN}`
        ]
      ) ||
      ["ERROR", "SUCCESS"].includes(
        props.buttonLoadCheck?.[
          `${CARD_OR_QUEUE_ID}_${OPERATING_ORDER_ACTION.CANCEL_CHECK_IN}`
        ]
      ) ||
      ["ERROR", "SUCCESS"].includes(
        props.buttonLoadCheck?.[
          `${CARD_OR_QUEUE_ID}_${OPERATING_ORDER_ACTION.ZONE}`
        ]
      )
    ) {
      closeModConfirm();
    }
  }, [props.buttonLoadCheck]);

  useEffect(() => {
    if (modConfirmData?.action === OPERATING_ORDER_ACTION.CANCEL_CHECK_IN) {
      handlePrepareCancelCheckin();
    }
  }, [modConfirmData?.action]);

  // ----- filter
  const handleChangePatient = (
    id: any,
    hn: string,
    full_name: string,
    birthdate: string
  ) => {
    props.runSequence({
      sequence: "ORQueue",
      action: "SET_FILTER",
      data: {
        emr__encounter__patient__hn: hn,
        patientFullName: full_name,
      },
    });
  };

  const handleSelectedDoctor = (item: any) => {
    props.runSequence({
      sequence: "ORQueue",
      action: "SET_FILTER",
      data: { selectedDoctor: item, chief_surgeon: item?.id || null },
    });
  };

  const handleChangeTypeStatus = (_event: any, data: any) => {
    props.runSequence({
      sequence: "ORQueue",
      action: "SET_FILTER",
      data: { [data.name]: data.value },
    });
  };

  const handleChangeLocationRoom = (_event: any, data: any) => {
    if (data.name === "location") {
      props.runSequence({
        sequence: "ORQueue",
        action: "FILTER_LOCATION_AND_FETCH_ROOM_ITEM",
        location: data.value,
      });
    } else if (data.name === "room") {
      props.runSequence({
        sequence: "ORQueue",
        action: "SET_FILTER",
        data: { operating_detail__operating_room__id: data.value },
      });
    }
  };

  const handleChangeNoDateRoom = (_event: any, data: any) => {
    let payload: any = { [data.name]: data.checked };
    if (data.checked) {
      payload = {
        ...payload,
        start_date: "",
        end_date: "",
        operating_detail__operating_room__location_id: "",
        operating_detail__operating_room__id: "",
      };
    }
    props.runSequence({
      sequence: "ORQueue",
      action: "SET_FILTER",
      data: payload,
    });
  };

  const handleSearch = () => {
    props.runSequence({
      sequence: "ORQueue",
      action: "FETCH",
      is_drug_queue: false,// Feature #68372 // props.config?.showFilterDrugQueue || false,
      is_ward_queue: props.config?.showFilterWardQueue || false,
    });
  };

  // ----- modal
  const closeModConfirm = () => {
    setModConfirmData({
      open: false,
      action: "",
      title: "",
      reason: "",
      order: null,
    });
  };

  const closeModChangeZone = () => {
    setModChangeZone({
      open: false,
      action: "",
      zone: "",
      location: "",
      room: "",
      order: null,
    });
  };

  // ----- popup action

  const handleActionCallback = (order: any, action: string) => {
    switch (action) {
      case OPERATING_ORDER_ACTION.CONFIRM:
        return setOpenModORRequest(order);

      case OPERATING_ORDER_ACTION.SCHEDULE:
        return props.runSequence({
          sequence: "ORQueue",
          action: OPERATING_ORDER_ACTION.SCHEDULE,
          order: order,
        });

      case OPERATING_ORDER_ACTION.CANCEL_SCHEDULE:
        return setModConfirmData({
          open: true,
          action: OPERATING_ORDER_ACTION.CANCEL_SCHEDULE,
          title: "ต้องการยกเลิก Schedule หรือไม่",
          reason: "",
          order: order,
        });

      case OPERATING_ORDER_ACTION.CHECK_IN:
        return props.runSequence({
          sequence: "ORQueue",
          action: OPERATING_ORDER_ACTION.CHECK_IN,
          order: order,
        });

      case OPERATING_ORDER_ACTION.CANCEL_CHECK_IN:
        return setModConfirmData({
          open: false,
          action: OPERATING_ORDER_ACTION.CANCEL_CHECK_IN,
          title: "",
          reason: "",
          order: order,
        });

      case OPERATING_ORDER_ACTION.ZONE:
        return setModChangeZone({
          open: true,
          action: OPERATING_ORDER_ACTION.ZONE,
          zone: "",
          location: "",
          room: "",
          order: order,
        });

      case OPERATING_ORDER_ACTION.ZONELOG:
        return setModZoneLog({ open: true, order: order });

      default:
        return closeModConfirm();
    }
  };

  const getButtonStyleConfig = useCallback(
    (item: any) => (action: string) => {
      const baseStyle = { margin: "7.5px 0", width: "150px" };
      const allowedActions: string[] = item?.allowed_action || [];
      const isZoneAction =
        action === OPERATING_ORDER_ACTION.ZONE &&
        allowedActions.some((r: string) =>
          [
            OPERATING_ORDER_ACTION.ZONE_HOLDING_ROOM,
            OPERATING_ORDER_ACTION.ZONE_OPERATING_ROOM,
            OPERATING_ORDER_ACTION.ZONE_PACU,
          ].includes(r)
        );

      const isAllowedAction = allowedActions.includes(action);

      const isConfirmOrZoneLog = [
        OPERATING_ORDER_ACTION.CONFIRM,
        OPERATING_ORDER_ACTION.ZONELOG,
      ].includes(action);

      const display = isZoneAction || isAllowedAction || isConfirmOrZoneLog ? "" : "none";

      return {
        ...baseStyle,
        display,
      };
    },
    []
  );

  const getPreOperatingSummary = useCallback((teams: Record<string, any>[]) => {
    const teamIndex = teams.findIndex((team: Record<string, any>) => team.is_main);
    const team = teams[teamIndex];

    if (!team) {
      return null;
    }

    const operatingTreatments: Record<string, any>[] =
      team.pre_operating_order_item?.operating_treatments || [];

    const otherTreatment = team.pre_operating_order_item?.other_treatment || "";

    const operationTreatment = operatingTreatments
      .map((treatment, index) => `${treatment.name_code}`)
      .join(" ");

    const truncate = (text: string, maxLength: number) =>
      text.length > maxLength ? text.slice(0, maxLength) : text;

    let operationTreatmentSummary = "";
    let otherTreatmentSummary = "";
    let isTextOverflow = false;

    if (operationTreatment.length > MAX_LENGTH) {
      operationTreatmentSummary = truncate(operationTreatment, MAX_LENGTH);
      isTextOverflow = true;
    } else {
      operationTreatmentSummary = operationTreatment;
      const remainingLength = MAX_LENGTH - operationTreatment.length;

      if (otherTreatment.length > remainingLength) {
        otherTreatmentSummary = truncate(otherTreatment, remainingLength);
        isTextOverflow = true;
      } else {
        otherTreatmentSummary = otherTreatment;
      }
    }

    return (
      <>
        <strong>{operationTreatmentSummary && `${operationTreatmentSummary} `}</strong>
        {otherTreatmentSummary}{" "}
        {isTextOverflow && (
          <Popup
            content={
              <>
                <strong>{operationTreatment}</strong> {otherTreatment}
              </>
            }
            trigger={
              <span>
                {[0, 1, 2].map((value) => (
                  <Icon
                    key={`dot-${value}`}
                    name="dot circle"
                    style={{ margin: 0, fontSize: "0.35rem", color: "#0147a3" }}
                  />
                ))}
              </span>
            }
            position="right center"
          />
        )}
      </>
    );
  }, []);

  const orOrderItems = useMemo(() => {
    return props.ORQueueSequence?.orOrders?.items
      ?.sort((a: any, b: any) => (a.start_date < b.start_date ? -1 : 1))
      ?.map((item: any, index: number) => {
        let diag = item.teams
          .flatMap((team: any) => {
            if (team.is_main) {
              let result = team.pre_principal_diagnosis?.[0]?.icd_code
                ? `${
                    team.pre_principal_diagnosis?.[0]?.medical_description
                      ? team.pre_principal_diagnosis?.[0]?.medical_description + " / "
                      : ""
                  }[${team.pre_principal_diagnosis?.[0]?.icd_code}] ${
                    team.pre_principal_diagnosis?.[0]?.icd_term
                  } ${
                    team.diagnosis_remark &&
                    "<br /><div style=color:#0147A3;display:flex;><div><i class='info circular icon' style=font-size:12px;></i></div>" +
                      team.diagnosis_remark +
                      "</div>"
                  }`
                : "";
              return [result];
            } else {
              return [];
            }
          })
          .join(", ");

        const endDate = formatDate(moment(item.end_date_time));

        return {
          ...item,
          pre_operation_product_summary: getPreOperatingSummary(item.teams),
          surgeryDatetime: (
            <>
              <div>
                {item.start_date}
                <Divider style={{ margin: "0.35rem 0 0.25rem", width: "95%" }} />
                {moment(item.start_time, "HH:mm:ss").format("HH:mm")} -{" "}
                {moment(item.end_date_time).format("HH:mm")}
                {"\n"}
                {endDate !== item.start_date && `(${endDate})`}
              </div>
            </>
          ),
          menu: (
            <div style={{ display: "flex", justifyContent: "center" }}>
              {/* <ActionMenuColumn
                // function
                setProp={props.setProp}
                onCallback={handleActionCallback}
                runSequence={props.runSequence}
                // data
                order={item}
                buttonLoadCheck={props.buttonLoadCheck}
              /> */}
              <ActionMenuColumn
                setProp={props.setProp}
                actions={[
                  "CHECK_IN",
                  "CANCEL_CHECK_IN",
                  "SCHEDULE",
                  "CANCEL_SCHEDULE",
                  "ZONE",
                  "CONFIRM",
                  "ZONELOG",
                ]}
                keepOpenActions={[
                  "CHECK_IN",
                  "CANCEL_CHECK_IN",
                  "SCHEDULE",
                  "CANCEL_SCHEDULE",
                  "ZONE",
                ]}
                buttonColors={{
                  [OPERATING_ORDER_ACTION.CHECK_IN]: "green",
                  [OPERATING_ORDER_ACTION.CANCEL_CHECK_IN]: "red",
                  [OPERATING_ORDER_ACTION.SCHEDULE]: "blue",
                  [OPERATING_ORDER_ACTION.CANCEL_SCHEDULE]: "red",
                  [OPERATING_ORDER_ACTION.ZONE]: "purple",
                  [OPERATING_ORDER_ACTION.CONFIRM]: "yellow",
                  [OPERATING_ORDER_ACTION.ZONELOG]: "grey",
                }}
                buttonLabels={{
                  [OPERATING_ORDER_ACTION.CHECK_IN]: "รับเข้า",
                  [OPERATING_ORDER_ACTION.CANCEL_CHECK_IN]: "ยกเลิกรับเข้า",
                  [OPERATING_ORDER_ACTION.SCHEDULE]: "Schedule",
                  [OPERATING_ORDER_ACTION.CANCEL_SCHEDULE]: "Cancel Schedule",
                  [OPERATING_ORDER_ACTION.ZONE]: "รับเข้า Zone",
                  [OPERATING_ORDER_ACTION.CONFIRM]: "รายละเอียด",
                  [OPERATING_ORDER_ACTION.ZONELOG]: "Zone Log",
                }}
                cardId={CARD_OR_QUEUE_ID}
                fluidButtons={true}
                item={item}
                buttonLoadCheck={props.buttonLoadCheck}
                getButtonStyle={getButtonStyleConfig(item)}
                onCallback={(action) => {
                  handleActionCallback(item, action);
                }}
              />
            </div>
          ),
          locationRoom: (
            <>
              {item?.location_name} / {item?.operating_room_no}
            </>
          ),
          diagnosis: <div dangerouslySetInnerHTML={{ __html: diag }} />,
          patientNameCheckinCode: (
            <>
              {item?.patient_name}
              <br />
              {item?.checkin_code && (
                <label style={{ background: "#77e0eb", padding: "2px 10px" }}>
                  {item.checkin_code}
                </label>
              )}
            </>
          ),
          statusNote: (
            <>
              {item?.status_label}
              <br />
              {item?.status_name === "CANCELED" && (
                <>
                  <b>เหตุผล: </b>
                  {item?.cancel_note}
                </>
              )}
            </>
          ),
          blood_request_summary:
            item?.blood_request_summary === "Yes" ? (
              <div>
                <div>{item?.blood_request_summary}</div>
                <div>
                  {item?.blood_request?.blood_component?.split(", ")?.map((bc: any) => (
                    <>{bc}</>
                  ))}
                </div>
              </div>
            ) : (
              item.blood_request_summary
            ),
          special_equipments_text_lbl: (
            <>
              {item?.special_equipments_text.slice(0, 45)}
              {item?.special_equipments_text.length > 45 && (
                <div
                  style={{ display: "inline-flex", gap: "0px" }}
                  title={item?.special_equipments_text}
                >
                  <Icon color="blue" size="tiny" name="dot circle" />
                  <Icon color="blue" size="tiny" name="dot circle" />
                  <Icon color="blue" size="tiny" name="dot circle" />
                </div>
              )}
            </>
          ),
        };
      });
  }, [props.ORQueueSequence?.orOrders?.items, props.buttonLoadCheck, getPreOperatingSummary]);

  const handleChangeReason = (_event: any, data: any) => {
    setModConfirmData({ ...modConfirmData, reason: data.value });
  };

  const handlePrepareCancelCheckin = () => {
    if (modConfirmData.order?.order_status === "APPOINTMENT") {
      props.setProp(
        `errorMessage.${CARD_OR_QUEUE_ID}`,
        "ไม่สามารถรับเข้าได้ เนื่องจากยังไม่ได้เปิด OPD Encounter"
      );
      closeModConfirm();
    } else {
      setModConfirmData({
        ...modConfirmData,
        open: true,
        title: "ต้องการยกเลิกรับเข้าหรือไม่",
      });
    }
  };

  const handleCancel = () => {
    props.runSequence({
      sequence: "ORQueue",
      action: modConfirmData.action,
      order: modConfirmData.order,
      cancel_reason: modConfirmData.reason,
    });
  };

  const handleChangeZone = () => {
    props.runSequence({
      sequence: "ORQueue",
      action: modChangeZone.action,
      zone: modChangeZone.zone,
      location: modChangeZone.location,
      room: modChangeZone.room,
      order: modChangeZone.order,
    });
    setModChangeZone({
      open: false,
      action: "",
      zone: "",
      location: "",
      room: "",
      order: null,
    });
  };

  const handleChangeDropdownZone = (_event: any, data: any) => {
    if (data.value === "ZONE_OPERATING_ROOM") {
      // get roomZoneOptions
      props.runSequence({
        sequence: "ORQueue",
        action: "ZONE_FETCH_ROOM",
        location: modChangeZone.order.location,
      });

      setModChangeZone({
        ...modChangeZone,
        zone: data.value,
        location: modChangeZone.order.location,
        room: modChangeZone.order.operating_room,
      });
    } else {
      setModChangeZone({
        ...modChangeZone,
        zone: data.value,
        location: "",
        room: "",
      });
    }
  };

  const handleChangeDropdownLocation = (_event: any, data: any) => {
    setModChangeZone({
      ...modChangeZone,
      location: data.value,
      room: "",
    });
    props.runSequence({
      sequence: "ORQueue",
      action: "ZONE_FETCH_ROOM",
      location: data.value,
    });
  };

  const handleChangeDropdownRoom = (_event: any, data: any) => {
    setModChangeZone({
      ...modChangeZone,
      room: data.value,
    });
  };
  var start_date = props?.ORQueueSequence?.orFilter?.start_date;
  function checkStartdate(start_date?: any) {
    var t = start_date?.match(/^(\d{2})\/(\d{2})\/(\d{4})$/);
    if (t === null) return false;
    var d = +t?.[1],
      m = +t?.[2],
      y = +t?.[3];

    // Below should be a more acurate algorithm
    if (m >= 1 && m <= 12 && d >= 1 && d <= 31) {
      return true;
    }

    return false;
  }
  var end_date = props?.ORQueueSequence?.orFilter?.end_date;
  function checkEnddate(end_date?: any) {
    var t = end_date?.match(/^(\d{2})\/(\d{2})\/(\d{4})$/);
    if (t === null) return false;
    var d = +t?.[1],
      m = +t?.[2],
      y = +t?.[3];

    // Below should be a more acurate algorithm
    if (m >= 1 && m <= 12 && d >= 1 && d <= 31) {
      return true;
    }

    return false;
  }

  const [open, setOpen] = React.useState(false);
  const handlePrintLab = () => {
    if (
      props?.ORQueueSequence?.orFilter?.start_date === "" ||
      props?.ORQueueSequence?.orFilter?.end_date === ""
    ) {
      return setOpen(true);
    } else if (checkStartdate(start_date) === false || checkEnddate(end_date) === false) {
      return setOpen(true);
    } else {
      return props.runSequence({
        sequence: "ORQueue",
        action: "PRINT",
        is_drug_queue: props.config?.showFilterDrugQueue || false,
        is_ward_queue: props.config?.showFilterWardQueue || false,
      });
    }
  };

  const handleChangeOperatingDate = (name: string) => (date: string) => {
    // console.log("handleChangeOperatingDate: ", date, name)
    props.runSequence({
      sequence: "ORQueue",
      action: "SET_FILTER",
      data: { [name]: date },
    });
  };

  const handleDoubleClick = (rowInfo: any) => {
    if (!props.config?.showFilterWardQueue) {
      props.runSequence({
        sequence: "ORQueue",
        action: "SELECTED_ORDER",
        order: rowInfo?.original,
      });
      props.onEvent({
        message: "BillingQueueAction",
        params: {
          action: "select_encounter",
          encounterId: rowInfo?.original.encounter || null,
          card: "CardPreOperation",
        },
      });
      props?.forward();
    }
  };

  // -const handleTouchStart = (rowInfo: any) => {
  //   touchCountRef.current++;
  //   if (touchCountRef.current === 1) {
  //     touchTimerRef.current = setTimeout(function () {
  //       touchCountRef.current = 0;
  //     }, 300);
  //   } else if (touchCountRef.current === 2) {
  //     clearTimeout(touchTimerRef.current);
  //     touchCountRef.current = 0;
  //     handleDoubleClick(rowInfo);
  //   }
  // };

  return (
    <div style={props.cardStyle || {}}>
      {(props.errorMessage?.[CARD_OR_QUEUE_ID] || props.successMessage?.[CARD_OR_QUEUE_ID]) && (
        <SnackMessage
          onEvent={props.onEvent}
          onClose={() => {
            props.setProp(`errorMessage.${CARD_OR_QUEUE_ID}`, null);
            props.setProp(`successMessage.${CARD_OR_QUEUE_ID}`, null);
          }}
          error={props.errorMessage?.[CARD_OR_QUEUE_ID]}
          success={props.successMessage?.[CARD_OR_QUEUE_ID]}
        />
      )}

      <CardORQueueUX
        // config
        showFilterORQueue={props.config.showFilterORQueue}
        showFilterDrugQueue={props.config.showFilterDrugQueue}
        showFilterWardQueue={props.config.showFilterWardQueue}
        // function
        getSelectedRow={(state: any, rowInfo: any, column: any, instance: any) => {
          return {
            style: {
              backgroundColor:
                rowInfo?.original?.id &&
                !rowInfo?.original?.start_date &&
                !rowInfo?.original?.location_name &&
                !rowInfo?.original?.operating_room_no &&
                rowInfo?.original?.status_label === "รอยืนยัน"
                  ? "#FFE6B5"
                  : rowInfo?.original?.id && rowInfo?.original?.id === props.selectedOrOrder?.id
                  ? "#cccccc"
                  : "white",
            },
            // onTouchStart: () => handleTouchStart(rowInfo),
            // onDoubleClick: () => handleDoubleClick(rowInfo),
            onClick: () => handleDoubleClick(rowInfo),
          };
        }}
        onChangeTypeStatus={handleChangeTypeStatus}
        onChangeLocationRoom={handleChangeLocationRoom}
        onChangeNoDateRoom={handleChangeNoDateRoom}
        onDateChange={handleChangeOperatingDate}
        // options
        masterOptions={props.masterOptions}
        // data
        ORQueueSequence={props.ORQueueSequence}
        orOrderItems={orOrderItems}
        // component
        patientSearchBox={
          <CardPatientSearchBox
            controller={props.drugOrderQueueController}
            defaultValue={props.ORQueueSequence?.orFilter?.hn}
            fluid={true}
            onEnterPatientSearch={handleChangePatient}
          />
        }
        doctorSearchBox={
          <SearchBox
            onEvent={props.onEvent}
            searchedItemList={props.searchedItemList}
            selectedItem={props.ORQueueSequence?.orFilter?.selectedDoctor || null}
            setSelectedItem={handleSelectedDoctor}
            type="Doctor"
            toDisplay={(item: any) => item.name_code}
            style={{ width: "100%" }}
            fluid={true}
          />
        }
        startORDate={
          <DateTextBox
            // style={{ width: "145px" }}
            disabled={props.ORQueueSequence?.orFilter?.no_date_no_room}
            value={props.ORQueueSequence?.orFilter?.start_date || ""}
            // inputStyle={{ width: "145px" }}
            onChange={(date: string) => {
              props.runSequence({
                sequence: "ORQueue",
                action: "SET_FILTER",
                data: { start_date: date },
              });
            }}
          />
        }
        endORDate={
          <DateTextBox
            // style={{ width: "145px" }}
            disabled={props.ORQueueSequence?.orFilter?.no_date_no_room}
            value={props.ORQueueSequence?.orFilter?.end_date || ""}
            // inputStyle={{ width: "145px" }}
            onChange={(date: string) => {
              props.runSequence({
                sequence: "ORQueue",
                action: "SET_FILTER",
                data: { end_date: date },
              });
            }}
          />
        }
        creatORDate={
          <DateTextBox
            // style={{ width: "145px" }}
            value={props.ORQueueSequence?.orFilter?.created || ""}
            // inputStyle={{ width: "145px" }}
            onChange={(date: string) => {
              props.runSequence({
                sequence: "ORQueue",
                action: "SET_FILTER",
                data: { created: date },
              });
            }}
          />
        }
        buttonSearch={
          <ButtonLoadCheck
            // function
            setProp={props.setProp}
            onClick={handleSearch}
            // data
            paramKey={`${CARD_OR_QUEUE_ID}_FETCH`}
            buttonLoadCheck={props.buttonLoadCheck?.[`${CARD_OR_QUEUE_ID}_FETCH`]}
            // config
            color="blue"
            size="small"
            title="ค้นหา"
            style={{ minWidth: "max-content", margin: 0 }}
          />
        }
        buttonPrint={
          <ButtonLoadCheck
            // function
            setProp={props.setProp}
            onClick={
              handlePrintLab
              //props.runSequence({ sequence: "ORQueue", action: "PRINT" });
              // setOpen(true);
            }
            // data
            paramKey={`${CARD_OR_QUEUE_ID}_PRINT`}
            buttonLoadCheck={props.buttonLoadCheck?.[`${CARD_OR_QUEUE_ID}_PRINT`]}
            // config
            color="purple"
            size="small"
            title="พิมพ์ตารางผ่าตัด"
            style={{ minWidth: "max-content", margin: 0 }}
          />
        }
        startDate={props.ORQueueSequence?.orFilter?.start_date || ""}
        endDate={props.ORQueueSequence?.orFilter?.end_date || ""}
      />
      <Modal open={open} onClose={() => setOpen(false)} onOpen={() => setOpen(true)} size="mini">
        <Segment inverted className="red" style={{ margin: 0 }}>
          !แจ้งเตือน
        </Segment>
        <Modal.Content>
          <Grid>
            <Grid.Column textAlign="center">
              <p>กรุณา ระบุช่วงเวลาที่เริ่มต้น - เวลาที่สิ้นสุด</p>
              <p>ก่อน กด พิมพ์ตารางผ่าตัด</p>
            </Grid.Column>
          </Grid>
        </Modal.Content>
        <Modal.Actions>
          <Grid>
            <Grid.Column textAlign="center">
              <Button color="green" size="tiny" onClick={() => setOpen(false)}>
                ตกลง
              </Button>
            </Grid.Column>
          </Grid>
        </Modal.Actions>
      </Modal>
      <Modal
        closeIcon
        style={{ width: "95%", padding: "1rem 1rem", margin: "2rem 0 0" }}
        open={!!openModORRequest}
        onClose={() => setOpenModORRequest(null)}
        closeOnDimmerClick
      >
        <CardORRequest
          // callback
          onEvent={props.onEvent}
          setProp={props.setProp}
          runSequence={props.runSequence}
          // controller
          proxyController={props.proxyController}
          // data
          ORRequestSequence={props.ORRequestSequence}
          searchedItemListWithKey={props.searchedItemListWithKey}
          masterData={props.masterData}
          encounterId={openModORRequest?.encounter}
          operatingOrderId={openModORRequest?.id}
          isNewEncounter={openModORRequest?.is_new_order_encounter}
          patientId={openModORRequest?.patient}
          loadingStatus={props.loadingStatus}
          buttonLoadCheck={props.buttonLoadCheck}
          userTokenize={props.userTokenize}
          divisionId={props.divisionId}
          cardStyle={{ height: "88vh" }}
          onSaveSuccess={() => {
            setOpenModORRequest(null);
          }}
          // patientAppointmentId={openModORRequest?.id}
          errorMessage={props.errorMessage}
          // options
          masterOptions={props.masterOptions}
          // config
          isConfirm={props.ORRequestSequence?.status === 1}
          isCancelConfirm={props.ORRequestSequence?.status === 2}
          isCancel={true}
        />
      </Modal>

      <Modal
        closeIcon
        size="large"
        open={modZoneLog.open}
        onClose={() => setModZoneLog({ open: false, order: null })}
        closeOnDimmerClick
      >
        <CardZoneLog
          // seq
          runSequence={props.runSequence}
          ZoneLogSequence={props.ZoneLogSequence}
          // data
          encounter={modZoneLog.order?.encounter}
        />
      </Modal>

      <Modal size="mini" closeIcon open={modConfirmData.open} onClose={closeModConfirm}>
        <Segment inverted className="blue" style={{ margin: "0px" }}>
          {modConfirmData.title}
        </Segment>
        <Modal.Content>
          <p>เหตุผลในการยกเลิก</p>
          <Input fluid value={modConfirmData.reason || ""} onChange={handleChangeReason} />
        </Modal.Content>
        <Modal.Actions>
          <Button color="green" onClick={handleCancel}>
            ใช่
          </Button>
          <Button color="red" onClick={closeModConfirm}>
            ไม่
          </Button>
        </Modal.Actions>
      </Modal>

      <Modal size="mini" closeIcon open={modChangeZone.open} onClose={closeModChangeZone}>
        <Segment inverted className="blue" style={{ margin: "0px" }}>
          กรุณาเลือก Zone ที่ต้องการรับเข้า
        </Segment>
        <Modal.Content>
          <Form>
            <Form.Group inline>
              <Form.Field width={4} required>
                <label>Zone</label>
              </Form.Field>
              <Form.Field width={11}>
                <Dropdown
                  className="fluidDropdown"
                  selection
                  value={modChangeZone.zone}
                  options={[
                    {
                      key: OPERATING_ORDER_ACTION.ZONE_HOLDING_ROOM,
                      value: OPERATING_ORDER_ACTION.ZONE_HOLDING_ROOM,
                      text: "Holding Room",
                    },
                    {
                      key: OPERATING_ORDER_ACTION.ZONE_OPERATING_ROOM,
                      value: OPERATING_ORDER_ACTION.ZONE_OPERATING_ROOM,
                      text: "Operating Room",
                    },
                    {
                      key: OPERATING_ORDER_ACTION.ZONE_PACU,
                      value: OPERATING_ORDER_ACTION.ZONE_PACU,
                      text: "PACU",
                    },
                  ]}
                  onChange={handleChangeDropdownZone}
                />
              </Form.Field>
            </Form.Group>

            <Form.Group
              inline
              style={{
                display:
                  modChangeZone.zone !== OPERATING_ORDER_ACTION.ZONE_OPERATING_ROOM ? "none" : "",
              }}
            >
              <Form.Field width={4} required>
                <label>Location</label>
              </Form.Field>
              <Form.Field width={11}>
                <Dropdown
                  className="fluidDropdown"
                  selection
                  value={modChangeZone.location}
                  options={props.ORQueueSequence?.locationOptions || []}
                  onChange={handleChangeDropdownLocation}
                />
              </Form.Field>
            </Form.Group>

            <Form.Group
              inline
              style={{
                display:
                  modChangeZone.zone !== OPERATING_ORDER_ACTION.ZONE_OPERATING_ROOM ? "none" : "",
              }}
            >
              <Form.Field width={4} required>
                <label>Room</label>
              </Form.Field>
              <Form.Field width={11}>
                <Dropdown
                  className="fluidDropdown"
                  selection
                  value={modChangeZone.room}
                  options={props.ORQueueSequence?.roomZoneOptions || []}
                  onChange={handleChangeDropdownRoom}
                />
              </Form.Field>
            </Form.Group>
          </Form>
        </Modal.Content>
        <Modal.Actions>
          <Button color="green" onClick={handleChangeZone}>
            ใช่
          </Button>
          <Button color="red" onClick={closeModChangeZone}>
            ไม่
          </Button>
        </Modal.Actions>
      </Modal>
    </div>
  );
};

CardORQueue.defaultProps = CardORQueueInitial;

export default React.memo(CardORQueue);
