import { degrees, PDFDocument } from "pdf-lib";
import fontkit from "@pdf-lib/fontkit";
import CONFIG from "config/config";
import { HeaderLogoWithTitleContent } from "react-lib/apps/HISV3/common/HeaderPdfFormTemplate";
import { getLogoReportNResize } from "react-lib/apps/HISV3/common/CommonInterface";

const FORM_NAME = "FormNurseNote";
const LOGO_HEIGHT = CONFIG.COMPANY === "CU" ? 40 : 30;

const base64toBlob = (data: string) => {
  // Cut the prefix `data:application/pdf;base64` from the raw base 64
  const base64WithoutPrefix = data.substr("data:application/pdf;base64,".length);
  const bytes = atob(base64WithoutPrefix);
  let length = bytes.length;
  const out = new Uint8Array(length);

  while (length--) {
    out[length] = bytes.charCodeAt(length);
  }

  return new Blob([out], { type: "application/pdf" });
};

const imageExists = (url: any) => {
  return new Promise((resolve) => {
    const img = new Image();
    img.onload = () => resolve(true);
    img.onerror = () => resolve(false);
    img.src = url;
  });
};

export const FormNurseNote = async (props: any) => {
  var RiskLowColorKey = "#23B000";
  var RiskMidColorKey = "#FE7D34";
  var RiskHighColorKey = "#FF0000";

  const nurseNoteList = props.nurseNoteList.map((item: any) => {
    let data = item?.formatted_date?.split("[");
    let time = data?.[1]?.split("]");
    return [
      {
        stack: [
          {
            text: `${data?.[0] || ""}, ${time?.[0] || ""}`,
          },
          {
            text: `เวร: ${item.working_shift}`,
          },
          {
            text: `${item.nurse}`,
          },
        ],
        margin: [5, 5, 0, 0],
      },
      {
        stack: [
          {
            ul: [`${item.focus}`],
          },
        ],
        margin: [5, 5, 0, 0],
      },
      {
        stack: [
          {
            text: `A: ${item.diagnosis}`,
          },
          {
            text: `I: ${item.plan}`,
          },
          {
            text: `E: ${item.goal}`,
          },
        ],
        margin: [5, 5, 0, 0],
      },
    ];
  });

  const isProfileImage = await imageExists(props.profileImage);

  const headerForm = await HeaderLogoWithTitleContent({
    form: FORM_NAME,
    font: "THSarabunNew",
    pageMargins: [20, 285, 20, 20],
    headerMargins: [20, 10, 20, 0],
    logoHeight: LOGO_HEIGHT,
    styles: {
      facultyHeader: {
        fontSize: 16,
        bold: true,
        margin: [0, 0, 0, 0],
      },
      clinicHeader: {
        fontSize: 16,
        bold: true,
        margin: [0, 0, 0, 15],
      },
      fieldKey: {
        bold: true,
        fontSize: 14,
      },
      fieldKeyTitle: {
        bold: true,
        fontSize: 12,
      },
      fieldValue: {
        fontSize: 12,
      },
      tableNormal: {
        margin: [0, 5, 0, 5],
      },
      tableWithOutBorder: {
        margin: [0, 0, 0, 0],
        border: [false, false, false, false],
      },
      tableHeader: {
        fillColor: "lightgray",
      },
      tableHeaderNurseNote: {
        bold: true,
        fillColor: "lightgray",
        fontSize: 14,
      },
      tableHeaderExamination: {
        bold: true,
        fontSize: 13,
        color: "black",
        fillColor: "lightgray",
      },
      tableHeaderType: {
        bold: true,
        fontSize: 13,
        color: "black",
        fillColor: "lightgray",
      },
      tableExamination: {
        fontSize: 13,
        color: "black",
        margin: [0, 10, 0, 10],
      },
      tableDiagram: {
        fontSize: 14,
        bold: true,
        fillColor: "lightgray",
      },
      fieldKeyDiagram: {
        bold: true,
        fontSize: 13,
      },
      fieldValueDiagram: {
        fontSize: 13,
      },
      treamentPerformSubKey: {
        bold: true,
        fillColor: "lightgray",
        color: "black",
        fontSize: 13,
        margin: [10, 0, 0, 0],
      },
      treatmentPerformLine: {
        fillColor: "lightgray",
        color: "black",
        fontSize: 13,
        margin: [5, 0, 0, 0],
      },
      treatmentTableDetail: {
        fillColor: "lightgray",
        color: "black",
        fontSize: 13,
      },
      treatmentTableDetailText: {
        color: "black",
        fontSize: 13,
      },
      galleryMargin: {
        margin: [10, 10, 10, 10],
      },
      answerTab: {
        margin: [15, 0, 0, 0],
      },
      questionTab: {
        margin: [5, 0, 0, 0],
      },
      resultKey: {
        bold: true,
        fontSize: 15,
      },
      resultValueLow: {
        bold: true,
        fontSize: 15,
        color: RiskLowColorKey,
      },
      resultValueMid: {
        bold: true,
        fontSize: 15,
        color: RiskMidColorKey,
      },
      resultValueHigh: {
        bold: true,
        fontSize: 15,
        color: RiskHighColorKey,
      },
    },
    titleContent: [
      { text: props.divisionName || "", style: "clinicHeader", alignment: "center", fontSize: 15 },
    ],
  });
  const { images, styles } = headerForm;

  const logoResize = await getLogoReportNResize(LOGO_HEIGHT, 1, FORM_NAME);

  import("pdfmake/build/pdfmake").then((make) => {
    import("react-lib/assets/fonts/pdfFonts").then(async (font) => {
      let pdfMake = make.default;
      pdfMake.vfs = font.default;
      pdfMake.fonts = {
        THSarabunNew: {
          normal: "THSarabunNew.ttf",
          bold: "THSarabunNew-Bold.ttf",
          italics: "THSarabunNew-Italic.ttf",
          bolditalics: "THSarabunNew-BoldItalic.ttf",
        },
        Roboto: {
          normal: "Roboto-Regular.ttf",
          bold: "Roboto-Medium.ttf",
          italics: "Roboto-Italic.ttf",
          bolditalics: "Roboto-MediumItalic.ttf",
        },
      };

      var origin = window?.location?.origin;

      // playground requires you to assign document definition to a variable called dd

      var docDefinition = {
        info: {
          title: props.patient_name,
          author: props.author,
          subject: "Nurse's note Report",
          keywords: "Nurse's note",
        },

        defaultStyle: {
          font: "THSarabunNew",
          // alignment: 'justify'
          lineHeight: 1,
          fontSize: 13,
        },
        footer: function (currentPage: number, pageCount: number) {
          return {
            columns: [
              {
                text: `วันที่และเวลาพิมพ์ ${props.printDate}`,
                alignment: "left",
                margin: [10, 0, 0, 0],
              },
              {
                text: "หน้าที่ " + currentPage.toString() + " / " + pageCount,
                alignment: "right",
                margin: [0, 0, 10, 0],
              },
            ],
          };
        },
        pageMargins: [10, 145, 10, 20],
        //A4: [595.28, 841.89]
        pageSize: "A4",
        styles: {
          ...styles,
        },
        header: {
          margin: [10, 10, 10, 0],
          stack: [
            {
              table: {
                widths: ["*", 180, 100],
                body: [
                  [
                    {
                      stack: [
                        [
                          {
                            image: "logo",
                            width: logoResize.width,
                            height: LOGO_HEIGHT,
                            alignment: CONFIG.COMPANY === "CU" ? "center" : "left",
                            margin: [10, 15, 10, 10],
                          },
                        ],
                        [
                          {
                            text: "เอกสารบันทึกทางการพยาบาล",
                            style: "facultyHeader",
                            alignment: "center",
                          },
                        ],
                        [
                          {
                            text: props.divisionName || "",
                            style: "fieldValue",
                            alignment: "center",
                          },
                        ],
                      ],
                      border: [true, true, false, true],
                    },
                    {
                      stack: [
                        [
                          {
                            text: [
                              {
                                text:
                                  props.encounter_type === "OPD"
                                    ? "HN [EN] : "
                                    : props.encounter_type === "IPD"
                                    ? "HN [AN] : "
                                    : "HN : ",
                                style: "fieldKeyTitle",
                              },
                              {
                                text: `${props.hn} [${props.encounter_id}]`,
                                style: "fieldValue",
                              },
                            ],
                          },
                        ],
                        [
                          {
                            text: [
                              { text: "ชื่อ-นามสกุล : ", style: "fieldKeyTitle" },
                              { text: props.patient_name, style: "fieldValue" },
                            ],
                          },
                        ],
                        [
                          {
                            columns: [
                              {
                                text: [
                                  { text: "วันเกิด : ", style: "fieldKeyTitle" },
                                  { text: props.patient_birthdate, style: "fieldValue" },
                                ],
                                width: "50%",
                              },
                              {
                                text: [
                                  { text: "เพศ : ", style: "fieldKeyTitle" },
                                  {
                                    text: props.gender_name === "Female" ? "หญิง" : "ชาย",
                                    style: "fieldValue",
                                  },
                                ],
                                width: "50%",
                              },
                            ],
                          },
                        ],
                        [
                          {
                            text: [
                              { text: "อายุ : ", style: "fieldKeyTitle" },
                              { text: props.full_age, style: "fieldValue" },
                            ],
                          },
                        ],
                        [
                          {
                            text: [
                              { text: "วันที่เข้ารับบริการ : ", style: "fieldKeyTitle" },
                              {
                                text: `${
                                  props.encounter_type === "OPD"
                                    ? props.encounterCreated || "-"
                                    : props.encounter_type === "IPD"
                                    ? props.admitDate || "-"
                                    : "-"
                                }`,
                                style: "fieldValue",
                              },
                            ],
                          },
                        ],
                        [
                          {
                            text: [
                              { text: "ADR : ", style: "fieldKeyTitle" },
                              { text: `${props.adverseReactionText || ""}`, style: "fieldValue" },
                            ],
                          },
                        ],
                      ],
                      margin: [10, 10, 0, 0],
                      border: [false, true, false, true],
                    },
                    {
                      table: {
                        widths: [80],
                        heights: [110],
                        body: [
                          [
                            isProfileImage
                              ? {
                                  image: "profileImage",
                                  width: 80,
                                  height: 110,
                                  alignment: "right",
                                }
                              : {
                                  text: `\n\n\nรูปภาพผู้ป่วย`,
                                  width: 80,
                                  height: 110,
                                  alignment: "center",
                                },
                          ],
                        ],
                      },
                      alignment: "right",
                      margin: [5, 5, 10, 5],
                      border: [false, true, true, true],
                    },
                  ],
                ],
              },
            },
          ],
        },
        content: [
          {
            margin: [0, 10, 0, 0],
            table: {
              widths: ["30%", "30%", "40%"],
              headerRows: 1,
              // keepWithHeaderRows: 1,
              body: [
                [
                  {
                    text: "วัน-เวลาบันทึก/เวร/ผู้บันทึก",
                    style: "tableHeaderNurseNote",
                    margin: [10, 10, 0, 0],
                  },
                  {
                    text: "Focus",
                    style: "tableHeaderNurseNote",
                    alignment: "center",
                    margin: [0, 10, 0, 0],
                  },
                  {
                    stack: [
                      { text: "Progress Note", style: "fieldKey", alignment: "center" },
                      {
                        text: "A:Assessment I:Intervention E:Evaluation",
                        style: "fieldKey",
                        alignment: "center",
                      },
                    ],
                    style: "tableHeaderNurseNote",
                    alignment: "center",
                  },
                ],
                ...nurseNoteList,
              ],
            },
          },
        ],
        images: {
          ...images,
          ...(isProfileImage && {
            profileImage: {
              url: props.profileImage,
            },
          }),
        },
      };

      // var win = window.open("", "_blank");
      // pdfMake.createPdf(docDefinition).open({}, win);
      console.log(" Start PDF Create ");
      // pdfMake.createPdf(docDefinition).download(props.hn + "-" + props.created_utc + ".pdf");
      const pdfDoc = await PDFDocument.create();

      // pdfMake.createPdf(docDefinition).open();
      const pdfDocGenerator = pdfMake.createPdf(docDefinition);
      pdfDocGenerator.getDataUrl(async (dataUrl) => {
        const doc = await PDFDocument.load(dataUrl);
        const copiedPages = await pdfDoc.copyPages(doc, doc.getPageIndices());

        const url = "/static/fonts/THSarabunNew-Bold.ttf";
        pdfDoc.registerFontkit(fontkit);
        const fontBytes = await fetch(url).then((res) => res.arrayBuffer());

        const timesRomanFont = await pdfDoc.embedFont(fontBytes);
        const currentDate = new Date();
        const year = (currentDate.getFullYear() + 543).toString().padStart(4, "0");
        const month = (currentDate.getMonth() + 1).toString().padStart(2, "0");
        const day = currentDate.getDate().toString().padStart(2, "0");
        const hour = currentDate.getHours().toString().padStart(2, "0");
        const min = currentDate.getMinutes().toString().padStart(2, "0");
        const stringDateTimeWaterMark = day + "/" + month + "/" + year + " " + hour + ":" + min;
        copiedPages.forEach((page) => {
          const pageDraw = pdfDoc.addPage(page);

          if (CONFIG.COMPANY === "CU") {
            pageDraw.drawText(
              "เอกสารควบคุม ห้ามเผยแพร่โดยไม่ได้รับอนุญาต \n คณะทันตแพทยศาสตร์ จุฬาลงกรณ์มหาวิทยาลัย \n เปิดดูโดย (" +
                props.author +
                " " +
                stringDateTimeWaterMark +
                ")",
              {
                font: timesRomanFont,
                x: 135,
                y: 400,
                size: 25,
                lineHeight: 30,
                opacity: 0.2,
                rotate: degrees(30),
              }
            );
          }
        });
        pdfDoc.setTitle(props.patient_name);
        pdfDoc.setAuthor(props.author);
        pdfDoc.setSubject("Visit Report");
        pdfDoc.setKeywords(["Examination Treatment"]);

        const base64Data = await pdfDoc.saveAsBase64();

        const blob = base64toBlob("data:application/pdf;base64," + base64Data);
        const bloburl = URL.createObjectURL(blob);
        window.open(bloburl);
      });
    });
  });
};
