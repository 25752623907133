import React from "react";
import { Divider, Form, Segment } from "semantic-ui-react";
import SubIcdDetail from "./SubIcdDetail";
import SubAddButton from "react-lib/apps/common/SubAddButton";

const ItemInitial = {
  medTern: "",
  icdTerm: "",
  icdCode: "",
};

const SubProvisionDiag = (props: any) => {
  const handleAddSecondaryDiag = () => {
    props.runSequence({ sequence: "DischargeSummary", action: "add_diag" });
  };

  const handleAddSecondaryProcedure = () => {
    props.runSequence({
      sequence: "DischargeSummary",
      action: "add_procedure",
    });
  };

  const handleChangeValue =
    (key: string, index: number) => (e: any, v: any) => {
      v.value = typeof v.checked === "boolean" ? v.checked : v.value;

      handleAction({ action: "change", key, index, data: v });
    };

  const handleClear = (key: string, index: number) => (e: any, v: any) => {
    handleAction({ action: "clear", key, index });
  };

  const handleRemove = (key: string, index: number) => (e: any, v: any) => {
    handleAction({ action: "remove", key, index });
  };

  const handleSearch = (key: string, index: number) => (params: any) => {
    handleAction({ action: "search", key, index, ...params });
  };

  const handleSelect = (key: string, index: number) => (data: any) => {
    const { icdType, ...res } = data;
    handleAction({ action: "select", key, index, icdType, selectItem: res });
  };

  const handleAction = (params: any) => {
    props.runSequence({
      sequence: "DischargeSummary",
      ...params,
    });
  };

  return (
    <div>
      <Segment
        style={{ backgroundColor: "rgb(173, 173, 173)", margin: "1.2rem 0" }}
      >
        {props.provisionalItems.map((item: any, index: number) => (
          <SubIcdDetail
            key={"sub-icd-provisional-" + index}
            item={item}
            primaryText="Provisional Primary Diagnosis"
            secondaryText="Provisional Secondary Diagnosis"
            icdType="ICD10"
            icdKeyUpSearchList={item.table}
            typeOptions={props.provisionalTypeOptions}
            subType={item.subType}
            active_disease={item.active_disease}
            showHeader={index === 0}
            showSubType={index >= 1}
            readOnly={true}
          />
        ))}
      </Segment>
      <Segment
        style={{ backgroundColor: "rgb(232, 244, 253)", margin: "1.2rem 0" }}
      >
        {props.diagnosisItems.map((item: any, index: number) => (
          <SubIcdDetail
            key={"sub-icd-diagnosis-" + index}
            item={item}
            primaryText="Final (Primary) Diagnosis"
            secondaryText="Secondary Diagnosis"
            icdType="ICD10"
            icdKeyUpSearchList={item.table}
            typeOptions={props.diagnosisTypeOptions}
            subType={item.subType}
            active_disease={item.active_disease}
            detail={item.detail}
            showHeader={index === 0}
            showSubType={index >= 1}
            removable={true}
            clearable={true}
            onChangeValue={handleChangeValue("diagnosisItems", index)}
            onClear={handleClear("diagnosisItems", index)}
            onRemove={handleRemove("diagnosisItems", index)}
            onSearch={handleSearch("diagnosisItems", index)}
            onSelect={handleSelect("diagnosisItems", index)}
          />
        ))}
        <SubAddButton
          header="เพิ่ม Secondary Diagnosis"
          onClicked={handleAddSecondaryDiag}
        />
      </Segment>
      <Segment
        style={{ backgroundColor: "rgb(227, 247, 222)", margin: "1.2rem 0" }}
      >
        {props.procedureItems.map((item: any, index: number) => (
          <SubIcdDetail
            key={"sub-icd-procedure-" + index}
            item={item}
            primaryText="Primary Procedure"
            secondaryText="Secondary Procedure"
            icdType="ICD9CM"
            icdKeyUpSearchList={item.table}
            detail={item.detail}
            showHeader={index === 0}
            hideActiveDisease={true}
            removable={true}
            clearable={true}
            // callback
            onChangeValue={handleChangeValue("procedureItems", index)}
            onClear={handleClear("procedureItems", index)}
            onRemove={handleRemove("procedureItems", index)}
            onSearch={handleSearch("procedureItems", index)}
            onSelect={handleSelect("procedureItems", index)}
          />
        ))}
        <SubAddButton
          header="เพิ่ม Secondary Procedure"
          onClicked={handleAddSecondaryProcedure}
        />
      </Segment>
    </div>
  );
};

export default SubProvisionDiag;
