import WasmController from "react-lib/frameworks/WasmController";

import FormEMRSummary, { FormEMRSummaryProps } from "HIS/PHV/FormEMRSummary";

import AdverseReactionList from "issara-sdk/apis/AdverseReactionList_apps_ADR";
import DiagnosisMedicalRecordDetail from "issara-sdk/apis/DiagnosisMedicalRecordDetail_apps_DPO";
import DoctorDetail from "issara-sdk/apis/DoctorDetail_core";
import EncounterDetail from "issara-sdk/apis/EncounterDetail_core";
import FormDataLatest from "issara-sdk/apis/FormDataLatest_apps_PTM";
import MedicalRecordGalleryList from "issara-sdk/apis/MedicalRecordGalleryList_apps_DPO";
import MedicationReconciliationNewDetail from "issara-sdk/apis/MedicationReconciliationNewDetail_apps_TPD";
import MedicationReconciliationNewList from "issara-sdk/apis/MedicationReconciliationNewList_apps_TPD";
import PatientEMRList from "issara-sdk/apis/PatientEMRList_apps_DPO";
import ProblemListList from "issara-sdk/apis/ProblemListList_apps_DPO";
import SupplyOrderDetail from "issara-sdk/apis/SupplyOrderDetail_apps_MSD";
import SupplyOrderList from "issara-sdk/apis/SupplyOrderList_apps_MSD";
import TreatmentOrderList from "issara-sdk/apis/TreatmentOrderList_apps_TRT";
import TriageFromEncounter from "issara-sdk/apis/TriageFromEncounter_core";

import { SORT_ADR_ORDER } from "react-lib/apps/HISV3/TPD/sequence/Allergy";
import getPdfMake from "react-lib/appcon/common/pdfMake";
import {
  EDUCATION,
  EDUCATION_EVALUATION,
  EDUCATION_WAY,
} from "react-lib/apps/HISV3/PTM/sequence/Assessment";
import {
  alcoholOptions,
  alcoholSpecificOptions,
  pregnancyPeriodOptions,
  tobaccoOptions,
  tobaccoSpecificOptions,
} from "react-lib/apps/HISV3/ADM/sequence/PreAssessment";

// Types and Interfaces
export type State = {};

export const StateInitial: State = {};

export type Event =
  | { message: "GetMasterData"; params: Record<string, unknown> }
  | { message: "PrintEMRSummaryForm"; params: any };

export type Data = {
  device?: number;
  division?: number;
  masterData: {
    [key: string]: Record<string, any>[] | undefined;
    patientCondition?: any[];
    patientDischarge?: any[];
    patientEducation?: any[];
    prenameEn: any[];
    prenameTh: any[];
    triageLevel?: any[];
  };
};

type VitalSignsKey = (typeof VITAL_SIGNS_KEY)[number][0];

type Handler = (controller: WasmController<State, Event, Data>, params?: any) => any;

// Constants
export const DataInitial = {};

const PREGNANCY_STATUS: Record<number, string> = {
  1: "ไม่ทราบ",
  2: "ไม่ตั้งครรภ์",
  3: "กำลังตั้งครรภ์",
};

const VITAL_SIGNS_KEY = [
  ["bmi", "BMI"],
  ["bp", "BP"],
  ["height", "Height"],
  ["o2sat", "O2Sat"],
  ["pr", "PR"],
  ["rr", "RR"],
  ["temp", "BT"],
  ["weight", "Weight"],
  ["ps", "PS"],
] as const;

export const SORT_DRUG_ORDER: Record<string, number> = {
  HOME_OPD: 4,
  ONE_DAY: 3,
  ONE_DOSE: 2,
  STAT: 1,
};

export const SORT_SUPPLY_ORDER: Record<string, number> = {
  COST_CENTER: 3,
  HOME_OPD: 4,
  REFILL_OPD: 2,
  STAT: 1,
};

// Functions
export const PrintEMRSummaryForm: Handler = async (controller, params) => {
  const { emr, encounterId, vitalSign } = params.emrSummaryData;
  const { patientData } = params.patientPanelData;

  await controller.handleEvent({
    message: "GetMasterData",
    params: {
      masters: [
        ["prenameTh", {}],
        ["prenameEn", {}],
        ["triageLevel", {}],
        ["patientEducation", {}],
        ["patientCondition", {}],
        ["patientDischarge", {}],
      ],
    },
  });

  const { masterData } = controller.data;

  const {
    adr,
    assessmentDetail,
    diagnosisDetail,
    doctorDetail,
    enDetail,
    gallery,
    medReconcileDetail,
    medRecordDetail,
    painDetail,
    pregnancyDetail,
    problem,
    reassessmentDetail,
    supply,
    treatment,
    triageDetail,
  } = await fetchPatientEncounterData({
    apiToken: controller.apiToken,
    encounterId,
    medicalRecordId: params.medicalRecordId,
    patientId: patientData.id,
  });

  const division = masterData.division?.find((item) => item.id === enDetail.division) || {};

  const doctorName = formatDoctorName(doctorDetail, masterData);

  const triage =
    masterData.triageLevel?.find((item) => item.id === triageDetail.triage_level) || {};

  const pregnancyPeriod = pregnancyPeriodOptions.find(
    (option) => option.value === pregnancyDetail?.data?.pregnancy_period
  );

  const problemItems: any[] = problem?.items || [];

  const underlyingDisease = problemItems
    .filter((item) => item.is_active)
    .map((item) => item.message as string)
    .join(", ");

  const allergyText = formatAllergy(adr?.items || []);

  const medRecItems: Record<string, any>[] = medReconcileDetail?.items || [];

  const currentMedication = medRecItems.map((item) => item.name as string).join(", ");

  const vitalSingItems: any[] = vitalSign?.items || [];

  const physicianNote = formatPhysicianNote(emr[1]);

  const { doctorNoteItems, drugItems, imagingItems, labItems, supplyItems } = getDoctorOrder(
    enDetail,
    supply
  );

  const dischargeStatus =
    masterData.patientDischarge?.find((item) => item.id === enDetail.discharge_status) || {};

  const preDischargeCondition =
    masterData.patientCondition?.find((item) => item.id === enDetail.predischarge_condition) || {};

  const docDef = await FormEMRSummary({
    allergyText,
    assessment: formatPatientAssessment(assessmentDetail),
    currentMedication,
    detail: {
      divisionName: division.name_en || division.name,
      doctorName,
      hn: enDetail.hn,
      number: enDetail.number,
      patient_age: enDetail.patient_age,
      patient_birthdate: enDetail.patient_birthdate,
      patient_gender_name: enDetail.patient_gender_name,
      patientName: patientData.full_name_en || patientData.full_name_th,
      profileImage: patientData.profile_image?.image,
      started: enDetail.started,
    },
    diagnosis: diagnosisDetail,
    discharge: {
      note: enDetail.discharge_note,
      preDischargeConditionName: preDischargeCondition.name || "",
      statusName: dischargeStatus.name || "",
    },
    doctorNoteItems,
    drugItems,
    galleryItems: gallery?.items || [],
    imagingItems,
    labItems,
    medicalRecord: medRecordDetail,
    painScore: painDetail?.data?.pain_score || "",
    physicianNote,
    pregnancy: {
      periodName: pregnancyPeriod?.text,
      statusName: PREGNANCY_STATUS[pregnancyDetail?.data?.pregnancy_status || ""],
    },
    reassessment: formatReassessmentEducation(reassessmentDetail, masterData),
    supplyOrders: supplyItems,
    treatmentOrders: treatment?.items || [],
    triage: {
      arrive_status: triageDetail.arrive_status,
      triageLevelName: triage.value,
    },
    underlyingDisease,
    vitalsign: formatVitalSigns(vitalSingItems.slice(-1)[0]?.vital_signs || []),
  });

  const pdfMake = await getPdfMake(true);
  const pdf = pdfMake.createPdf(docDef);

  pdf.open();
};

/* ------------------------------------------------------ */

/*                          APIS                          */

/* ------------------------------------------------------ */
const GetMedicationReconciliation = async (params: { apiToken: string; encounterId: number }) => {
  const medRecRes: Promise<[any, any]> = MedicationReconciliationNewList.list({
    apiToken: params.apiToken,
    params: { encounter: params.encounterId },
  });

  return medRecRes.then(async ([res]) => {
    const items: Record<string, any>[] = res?.items || [];

    const data = items.find((item) => item.med_reconciliation_type === "OPD");

    return data?.id
      ? (MedicationReconciliationNewDetail.retrieve({
          apiToken: params.apiToken,
          pk: data.id,
        }) as Promise<[any, any]>)
      : ([] as any[]);
  });
};

const GetSupplyOrderList = async (params: { apiToken: string; encounterId: number }) => {
  const supplyListRes: Promise<[any, any]> = SupplyOrderList.list({
    apiToken: params.apiToken,
    params: { encounter: params.encounterId },
  });

  return supplyListRes.then(async ([res]) => {
    const items: Record<string, any>[] = res?.items || [];

    const promiseArr = items.map(
      async (item) =>
        SupplyOrderDetail.retrieve({
          apiToken: params.apiToken,
          pk: item.id,
        }) as Promise<[any, any]>
    );

    return [await Promise.all(promiseArr)];
  });
};

const fetchPatientEncounterData = async (params: {
  apiToken: string;
  encounterId: number;
  medicalRecordId: string;
  patientId: string;
}) => {
  const { apiToken, encounterId, medicalRecordId, patientId } = params;

  // ดึงข้อมูล Encounter เพื่อใช้ในการเรียก API อื่นๆ
  const [enDetail] = await EncounterDetail.retrieve({
    apiToken,
    pk: encounterId,
  });

  const apiCalls = [
    DoctorDetail.retrieve({ apiToken, pk: enDetail.doctor }),
    TriageFromEncounter.retrieve({ apiToken, pk: encounterId }),
    FormDataLatest.retrieve({
      apiToken,
      params: { encounter: encounterId, form_code: "CardPregnancyAssessment", form_version: "1.0" },
    }),
    FormDataLatest.retrieve({
      apiToken,
      params: { encounter: encounterId, form_code: "CardPatientAssessment", form_version: "0.2" },
    }),
    FormDataLatest.retrieve({
      apiToken,
      params: { encounter: encounterId, form_code: "CardPainAssessment", form_version: "0.1" },
    }),
    FormDataLatest.retrieve({
      apiToken,
      params: { encounter: encounterId, form_code: "CardReassessment", form_version: "0.1" },
    }),
    DiagnosisMedicalRecordDetail.retrieve({ apiToken, pk: medicalRecordId }),
    ProblemListList.list({ apiToken, params: { patient: patientId } }),
    AdverseReactionList.list({
      apiToken,
      params: { exclude_unused: true, patient: patientId, severe_first: true },
    }),
    GetMedicationReconciliation({ apiToken, encounterId }),
    PatientEMRList.get({
      apiToken,
      emr: medicalRecordId,
      params: { auto_checkin: false },
      extra: { division: enDetail.division },
    }),
    TreatmentOrderList.list({
      apiToken,
      params: { encounter_id: encounterId },
    }),
    GetSupplyOrderList({ apiToken, encounterId }),
    MedicalRecordGalleryList.list({ apiToken, emr: medicalRecordId }),
  ];

  const apiCallResults = await Promise.all(apiCalls);

  const resultKeys = [
    "doctorDetail",
    "triageDetail",
    "pregnancyDetail",
    "assessmentDetail",
    "painDetail",
    "reassessmentDetail",
    "diagnosisDetail",
    "problem",
    "adr",
    "medReconcileDetail",
    "medRecordDetail",
    "treatment",
    "supply",
    "gallery",
  ] as const;

  const resultEntries = resultKeys.map(
    (key, index) => [key, apiCallResults[index][0]] as [(typeof resultKeys)[number], any]
  );
  const results = Object.fromEntries(resultEntries) as Record<(typeof resultKeys)[number], any>;

  return {
    enDetail,
    ...results,
  };
};

/* ------------------------------------------------------ */

/*                         Format                         */

/* ------------------------------------------------------ */
const formatDoctorName = (
  doctorDetail: Record<string, any>,
  masterData: { prenameEn?: any[]; prenameTh?: any[] }
) => {
  const isEnglish = masterData.prenameEn?.some((item) => item.id === doctorDetail.pre_name_en);
  const prename = isEnglish
    ? masterData.prenameEn?.find((item) => item.id === doctorDetail.pre_name_en)
    : masterData.prenameTh?.find((item) => item.id === doctorDetail.pre_name);

  const name = [
    isEnglish ? doctorDetail.first_name_en : doctorDetail.first_name,
    isEnglish ? doctorDetail.middle_name_en : doctorDetail.middle_name,
    isEnglish ? doctorDetail.last_name_en : doctorDetail.last_name,
  ]
    .filter(Boolean)
    .join(" ");

  let certificateNo: string = doctorDetail.certificate_no;

  certificateNo = certificateNo ? `(${certificateNo.replace("ว.", "")})` : "";

  return `${name}, ${prename?.name || ""} ${certificateNo}`;
};

const formatVitalSigns = (data: any[]) => {
  const result: Record<string, string> = {};

  for (const [key, oldKey] of VITAL_SIGNS_KEY) {
    const item = data.find((item) => item.vitalsign_type_code === oldKey);

    if (item) {
      result[key] = item.result;
    }
  }

  // สำหรับ blood pressure
  const systolic = data.find((item) => item.vitalsign_type_code === "SP");
  const diastolic = data.find((item) => item.vitalsign_type_code === "DP");

  if (systolic && diastolic) {
    result.bp = `${systolic.result}/${diastolic.result}`;
  }

  return result as Record<VitalSignsKey, string>;
};

const formatPhysicianNote = (data: Record<string, any>[]) => {
  const questionMap: Record<string, keyof FormEMRSummaryProps["physicianNote"] | undefined> = {
    "Chief Complaint": "chiefComplaints",
    "Family History": "familyHistory",
    "Past Illness": "pastIllness",
    "Personal History": "personalHistory",
    "Physician note (Present illness, Past history and relevant information)": "presentIllness",
  };

  const mappedEntries = data
    .filter((item) => questionMap[item.question])
    .map((item) => [questionMap[item.question], item.answer] as [string, string]);

  return { ...Object.fromEntries(mappedEntries) } as FormEMRSummaryProps["physicianNote"];
};

const formatReassessmentEducation = (
  reassessmentDetail: any,
  masterData: { patientEducation?: any[] }
) => {
  const reassessmentData = reassessmentDetail?.data || {};

  const educationEvaluation = EDUCATION_EVALUATION.find(
    (option) => option.value === reassessmentData.education_evaluation
  );

  const educationWay: string[] = reassessmentData.education_way || [];
  const educationWayName = educationWay
    .map((value) => EDUCATION_WAY.find((option) => option.value === value)?.text)
    .filter(Boolean)
    .join(", ");

  const education: string[] = reassessmentData.education || [];
  const educationName = education
    .map((value) => EDUCATION.find((option) => option.value === value)?.text)
    .filter(Boolean)
    .join(", ");

  const educationMethod: string[] = reassessmentData.education_method || [];
  const educationMethodName = educationMethod
    .map((value) => {
      const patientEducation: any[] = masterData.patientEducation || [];
      const text: string =
        patientEducation.find((option) => option.id === Number(value))?.name || "";

      return text;
    })
    .filter(Boolean)
    .join(", ");

  return reassessmentDetail?.data
    ? {
        barriers: reassessmentData.barriers,
        barriers_detail: reassessmentData.barriers_detail,
        education_evaluation_detail: reassessmentData.education_evaluation_detail,
        educationEvaluationName: educationEvaluation?.text,
        educationMethodName,
        educationName,
        educationWayName,
        follow_up_detail: reassessmentData.follow_up_detail,
        readiness: reassessmentData.readiness,
        readiness_detail: reassessmentData.readiness_detail,
      }
    : null;
};

const formatPatientAssessment = (assessmentDetail: any) => {
  const reassessmentData = assessmentDetail?.data || {};

  const alcohol = alcoholOptions.find((option) => option.value === reassessmentData.alcohol);
  const alcoholSpecific = alcoholSpecificOptions.find(
    (option) => option.value === reassessmentData.alcohol_specific
  );

  const tobacco = tobaccoOptions.find((option) => option.value === reassessmentData.tobacco);
  const tobaccoSpecific = tobaccoSpecificOptions.find(
    (option) => option.value === reassessmentData.tobacco_specific
  );

  return {
    alcoholName: alcohol?.text,
    alcoholSpecificName: alcoholSpecific?.text,
    tobaccoName: tobacco?.text,
    tobaccoSpecificName: tobaccoSpecific?.text,
  };
};

const formatAllergy = (adrItems: Record<string, any>[] | undefined) => {
  const sortedAdr = (adrItems || []).sort(
    (a, b) => SORT_ADR_ORDER[a.type_name_name] - SORT_ADR_ORDER[b.type_name_name]
  );

  return sortedAdr
    .map((acc) => acc.name as string)
    .filter(Boolean)
    .join(", ");
};

const getDoctorOrder = (enDetail: any, supply: any[]) => {
  const doctorOrders: Record<string, any>[] = enDetail.doctor_orders || [];

  const filterAndMapOrders = (
    specificType: string,
    mapFunction: (item: Record<string, any>) => { name: string }[]
  ) =>
    doctorOrders
      .filter((item) => item.specific_type === specificType)
      .flatMap((element) => mapFunction(element));

  const labItems = filterAndMapOrders("centrallaborder", (item) => {
    const specificItemsStatus: Record<string, { name: string }> =
      item.order_summary_detail_cache.specific_items_status;

    return Object.values({ ...specificItemsStatus });
  });

  const imagingItems = filterAndMapOrders("imagingorder", (item) => {
    const specificItemsStatus: Record<string, { name: string }> =
      item.order_summary_detail_cache.specific_items_status;

    return Object.values({ ...specificItemsStatus });
  });

  const formatDrugItems = doctorOrders
    .filter((item) => (item.specific_type as string).includes("drug"))
    .flatMap((item) => {
      const summary = item.order_summary_detail_cache;

      const medicationDetails = extractMedicationInfo(summary.order_summary_detail).map((acc) => ({
        ...acc,
        specificTypeName: summary.specific_type.name,
      }));

      return medicationDetails.map((med, index) => {
        // ตรวจสอบว่าเป็น solvent และ index > 0 (เพื่อให้มีรายการก่อนหน้า)
        if (med.isSolvent && index > 0) {
          // เอา `name` จาก index ก่อนหน้ามา concat กับ full_name ของ solvent
          const previousName = medicationDetails[index - 1].full_name;

          return { ...med, for_mixing: previousName };
        }

        // ถ้าไม่ใช่ solvent หรือ index = 0 ให้คืนค่าตามเดิม
        return med;
      });
    });

  const sortedDrug = formatDrugItems.sort(
    (a, b) => SORT_DRUG_ORDER[a.specificTypeName] - SORT_DRUG_ORDER[b.specificTypeName]
  );

  const formatSupplyItems = (supply as any[])
    .map((item) => item[0] as Record<string, any>)
    .filter((item) => item.status !== "CANCELED")
    .flatMap((item) =>
      (item.items as any[]).map((acc) => ({
        name: acc.name,
        quantity_request: acc.quantity_request,
        stock_unit: acc.stock_unit,
        type: item.type,
      }))
    );

  const sortedSupply = formatSupplyItems.sort(
    (a, b) => SORT_SUPPLY_ORDER[a.type] - SORT_SUPPLY_ORDER[b.type]
  );

  const doctorNoteItems = filterAndMapOrders("doctornoteorder", (item) => [
    {
      name: item.order_summary_detail_cache.order_summary_detail,
    },
  ]);

  return {
    doctorNoteItems,
    drugItems: sortedDrug,
    imagingItems,
    labItems,
    supplyItems: sortedSupply,
  };
};

/* ------------------------------------------------------ */

/*                          Utils                         */

/* ------------------------------------------------------ */
const NAME_WITH_PARENTHESES_REGEX = /[^(]+\([^)]+\)/; // Match the name with parentheses

const LABEL_DURATION_REGEX = /(?=(?:\d+\s+\w+|\d\w+)$)/;

const extractMedicationInfo = (input: string) =>
  input
    .split("<br/><b>")
    .slice(1)
    .map((med) => {
      // แยกชื่อยาและรายละเอียดออกจากกัน
      const [fullName, ...details] = med.split("</b>");

      // ลบ HTML tags โดยไม่ใช้การจับกลุ่ม
      const cleanDetails = details
        .join("")
        .replaceAll(/<[^>]+>/g, "") // ลบ HTML tags โดยไม่ใช้กลุ่มจับ
        .replaceAll(/\s+/g, " ") // แทนที่ช่องว่างหลายช่องด้วยช่องว่างเดียว
        .trim(); // ตัดช่องว่างหัวท้าย

      // แยกส่วนของ label และ duration โดยใช้ regex ที่อยู่ใน top-level scope
      const [label, duration] = cleanDetails.split(LABEL_DURATION_REGEX).map((s) => s.trim());

      // ดึงชื่อยาในวงเล็บพร้อมกับชื่อยาเดิมออกมาเป็น `name`
      const nameMatch = NAME_WITH_PARENTHESES_REGEX.exec(fullName);
      const name = nameMatch ? nameMatch[0].trim() : fullName.trim();

      // ตรวจสอบว่ามี "VOLUME:" อยู่ใน cleanDetails หรือไม่
      const isSolvent = cleanDetails.includes("VOLUME:");

      // ส่งคืน object ที่มีข้อมูลยา
      return {
        full_name: fullName.trim(),
        isSolvent, // ถ้ามี "VOLUME:" ให้ isSolvent เป็น true
        label: duration ? `${label} ${duration}` : label,
        name, // ชื่อยาพร้อมวงเล็บ
      };
    });
