import React, { useMemo } from "react";
import { Icon, Tab, Form, Button, TabProps } from "semantic-ui-react";

// UI
import CardSurgeryTeamUX from "./CardSurgeryTeamUX";
import SubIcdDetail from "../ADM/SubIcdDetail";

// UX
import SubAssistantsInput from "./SubAssistantsInput";
import SearchBoxWithKey from "react-lib/appcon/common/SearchBoxWithKey";

// Interface
import {
  PickedProps,
  SEARCH_KEYS,
  SetProp,
  shiftArrayWithKey,
  TeamInitial,
  TeamType,
} from "./sequence/ORRequest";

import CONFIG from "config/config";

type CardSurgeryTeamProps = {
  onEvent: (e: any) => any;
  setProp: SetProp;
  // seq
  runSequence: any;
  masterData?: any;
  // data
  teams: TeamType[];
  activeIndex: number;
  isReadOnly?: boolean;
  allowedEditMain?: boolean;
  // options
  orImplantOptions: any[];
  // callback
  onChangeValue: (e: any, data: any) => any;
  onAddAnsTeam?: () => void;
} & Pick<PickedProps, "masterOptions" | "searchedItemListWithKey">;

const CardSurgeryTeam = (props: CardSurgeryTeamProps) => {


  const teamPanes = useMemo(() => {
    return props.teams?.map((item: any, index: number) => ({
      menuItem: {
        key: `MenuItem-team${index}`,
        id: `MenuItem-team${index}`,
        content: (
          <MenuItem
            index={index}
            activeIndex={props.activeIndex}
            title="Surgery Team"
            isStar={item.is_main}
          />
        ),
      },
      render: () => (
        <CardSurgeryTeamUX
          // data
          isMain={item.is_main}
          implant={item.implant}
          diagnosis_remark={item?.diagnosis_remark || ""}
          otherTreatment={item.pre_operating_order_item?.other_treatment}
          position={item.pre_operating_order_item?.position}
          otherPosition={item.pre_operating_order_item?.other_position}
          otherTrtPositionOptions={props.masterOptions.otherTrtPosition}
          siteRight={item.pre_operating_order_item?.site_right}
          siteLeft={item.pre_operating_order_item?.site_left}
          // options
          orImplantOptions={props.orImplantOptions}
          // callback
          onChangeValue={props.isReadOnly ? () => {} : handleChange("teams", index)}
          onDeleteTeam={props.isReadOnly ? () => {} : handleDeleteTeam}
          onAddition={props.isReadOnly ? () => {} : handleAddition(index)}
          // config
          config={{
            showOtherPosition: getHasDescription(item.pre_operating_order_item?.position),
            hideDiagnosisRemark: CONFIG.FEATURE_DISABLED_UP_TO_VERS["202401"],
            hideOperatingTreatment: CONFIG.HIDE_OPERATING_TREATMENT_OR,
          }}
          // Element
          AssistantsElement={
            <SubAssistantsInput
              // callback
              onEvent={props.onEvent}
              // data
              index={index}
              items={item.assistant_surgeons || []}
              type="Doctor"
              field="Assist_Surgeon"
              searchedItemListWithKey={props.searchedItemListWithKey}
              // setSelected={handleActionAssistantSurgeon(index)}
              // onClick={handleActionAssistantSurgeon(index)}
              onUpdated={handleUpdateAssistantSurgeon(index)}
              isReadOnly={props.isReadOnly}
            />
          }
          TreatmentElement={
            <SubAssistantsInput
              // callback
              onEvent={props.onEvent}
              // data
              index={index}
              items={item.pre_operating_order_item?.operating_treatments || []}
              type="OperatingTreatment"
              isDetail={true}
              searchedItemListWithKey={props.searchedItemListWithKey}
              // onClick={handleActionOperatingTreatment(index)}
              // setSelected={handleActionOperatingTreatment(index)}
              onUpdated={handleUpdateOperatingTreatment(index)}
              isReadOnly={props.isReadOnly}
            />
          }
          ProvisionDiagElement={
            <ProvisionDiagElement
              // data
              field="procedures"
              defaultLength={1}
              items={item.pre_operating_order_item?.procedures || []}
              showHeader={false}
              addable={true}
              deletable={true}
              showSubType={true}
              removable={false}
              icdType="ICD9CM"
              // options
              typeOptions={props.masterOptions.procedureType}
              // callback
              onChangeValue={props.isReadOnly ? () => {} : handleActionProvision("change", index)}
              onSearch={props.isReadOnly ? () => {} : handleSearch(index)}
              onSelect={props.isReadOnly ? () => {} : handleSelect(index)}
              onClear={props.isReadOnly ? () => {} : handleClear(index)}
              onAdd={props.isReadOnly ? () => {} : handleActionProvision("add", index)}
              onDelete={props.isReadOnly ? () => {} : handleActionProvision("delete", index)}
              isReadOnly={props.isReadOnly}
            />
          }
          PreoperativeDiagElement={
            <ProvisionDiagElement
              // data
              field="diagnosis"
              defaultLength={2}
              items={item.diagnosis || []}
              showHeader={true}
              addable={false}
              deletable={false}
              showSubType={false}
              removable={true}
              icdType="ICD10"
              // options
              typeOptions={props.masterOptions.procedureType}
              // config
              requiredMedicalTerm={item.is_main}
              // callback
              onChangeValue={props.isReadOnly ? () => {} : handleActionProvision("change", index)}
              onSearch={props.isReadOnly ? () => {} : handleSearch(index)}
              onSelect={props.isReadOnly ? () => {} : handleSelect(index)}
              onClear={props.isReadOnly ? () => {} : handleClear(index)}
              onAdd={props.isReadOnly ? () => {} : handleActionProvision("add", index)}
              onDelete={props.isReadOnly ? () => {} : handleActionProvision("delete", index)}
              isReadOnly={props.isReadOnly}
            />
          }
          SurgeonElement={
            <SearchBoxWithKey
              // callback
              onEvent={props.isReadOnly ? () => {} : props.onEvent}
              type="Doctor"
              id={`Surgeon_${index + 1}`}
              searchedItemListWithKey={props.searchedItemListWithKey}
              selectedItem={item.chief_surgeon || null}
              setSelected={handleChiefSurgeon(index)}
              disabled={props.isReadOnly || !props.allowedEditMain}
            />
          }
          isReadOnly={props.isReadOnly}
        />
      ),
    }));
  }, [
    props.teams,
    props.activeIndex,
    props.masterOptions,
    props.orImplantOptions,
    props.searchedItemListWithKey,
  ]);

  const getHasDescription = (id: number) => {
    if (props.masterData?.otherTrtPosition instanceof Array) {
      return (
        props.masterData?.otherTrtPosition?.find((item: any) => item?.id === id)
          ?.has_description || ""
      );
    }
  };

  const handleChiefSurgeon = (index: number) => async (value: any) => {
    await props.setProp(
      `ORRequestSequence.teams.${index}.chief_surgeon`,
      value || null
    );

    props.runSequence({
      sequence: "ORRequest",
      action: "update_location_room",
    });
  };

  const handleAddTeam = (e: any) => {
    props.runSequence({ sequence: "ORRequest", action: "add_team" });
  };

  const handleSelectTeam = (e: any, data: TabProps) => {
    if (props.teams?.length === Number(data.activeIndex)) {
      if (props.isReadOnly) {
        return;
      }
      handleAddTeam(e);
      return;
    }

    props.setProp(
      `ORRequestSequence.activeSurgeonTeam`,
      Number(data.activeIndex)
    );
  };

  const handleChange =
    (key: string, index: number) => async (e: any, v: any) => {
      const lastKey = key.slice(-1)?.[0] || "";

      if (lastKey === "position" && !getHasDescription(v.value)) {
        await props.setProp(
          `ORRequestSequence.teams.${index}.pre_operating_order_item.other_position`,
          ""
        );
      }

      props.onChangeValue(e, { ...v, name: `${key}.${index}.${v.name}` });
    };

  const handleUpdateOperatingTreatment = (index: number) => (items: any[]) => {
    props.setProp(
      `ORRequestSequence.teams.${index}.pre_operating_order_item.operating_treatments`,
      items
    );
  };

  const handleUpdateAssistantSurgeon = (index: number) => (items: any[]) => {
    props.setProp(`ORRequestSequence.teams.${index}.assistant_surgeons`, items);
  };

  const handleDeleteTeam = async () => {
    const teams = props.teams || [];

    if (teams.length === 1) {
      teams[props.activeIndex] = { ...TeamInitial };
    } else {
      teams.splice(props.activeIndex, 1);

      const index = props.activeIndex - 2;

      await props.setProp(
        "ORRequestSequence.activeSurgeonTeam",
        index < 0 ? 0 : index
      );

      const listWithKey = props.searchedItemListWithKey || {};

      shiftArrayWithKey(
        listWithKey,
        {
          [SEARCH_KEYS.DOCTOR_ASSIST_SURGEON]: "CI",
          [SEARCH_KEYS.DOCTOR_SURGEON]: "I",
          [SEARCH_KEYS.OPERATING_TREATMENT]: "CI",
        },
        props.activeIndex + 1
      );

      props.setProp("searchedItemListWithKey", { ...listWithKey });
    }

    await props.setProp("ORRequestSequence.teams", [...teams]);

    props.runSequence({
      sequence: "ORRequest",
      action: "update_location_room",
    });
  };

  const handleActionProvision =
    (action: string, index: number) => (cIndex: any, v: any) => {
      let procedures: any[] = [];

      if (v.field === "procedures") {
        procedures =
          props.teams[index].pre_operating_order_item?.procedures || [];
      } else {
        procedures = props.teams[index].diagnosis || [];
      }

      if (action === "change") {
        procedures[cIndex][v.name] = v.value;
      } else if (action === "add") {
        procedures.push({ subType: "PRIMARY" });
      } else if (action === "delete") {
        procedures.splice(cIndex, 1);
      }

      props.setProp(
        `ORRequestSequence.teams.${index}.${
          v.field === "procedures"
            ? "pre_operating_order_item.procedures"
            : "diagnosis"
        }`,
        procedures
      );
    };

  const handleSearch = (index: number) => (cIndex: any, params: any) => {
    const { field, ...res } = params;

    handleAction({
      action: "search",
      key: field,
      index: cIndex,
      teamIndex: index,
      ...res,
    });
  };

  const handleSelect = (index: number) => (cIndex: any, data: any) => {
    const { icdType, field, ...res } = data;

    handleAction({
      action: "select",
      key: field,
      index: cIndex,
      teamIndex: index,
      icdType,
      selectItem: res,
    });
  };

  const handleClear = (index: number) => (cIndex: any, data: any) => {
    handleAction({
      action: "clear",
      key: data.field,
      index: cIndex,
      teamIndex: index,
    });
  };

  const handleAction = (params: any) => {
    props.runSequence({ sequence: "ORRequest", ...params });
  };

  const handleAddAnsTeam = (e: any) => {
    props.onAddAnsTeam?.();

    props.runSequence({ sequence: "ORRequest", action: "anesthesia_team" });
  };

  const handleAddition = (index: number) => (e: any, v: any) => {
    const options = props.orImplantOptions;

    props.setProp("ORRequestSequence.orImplantOptions", [
      { key: v.value, text: v.value, value: v.value },
      ...options,
    ]);
  };

  console.log("teamPanes : ", teamPanes);

  return (
    <div style={{ padding: "1rem 0", marginTop: "0rem", position: "relative" }}>
      <Form style={{ position: "absolute", width: "100%", paddingRight: "1rem" }}>
        <Form.Group inline={true}>
          <Form.Field width={14} />
          <Form.Field width={2}>
            <Button
              color="purple"
              fluid={true}
              style={{ minWidth: "max-content" }}
              onClick={handleAddAnsTeam}
              disabled={props.isReadOnly}
            >
              Add ANS
            </Button>
          </Form.Field>
        </Form.Group>
      </Form>

      <Tab
        id="enable-pointer-tap"
        activeIndex={props.activeIndex}
        menu={{ secondary: true, pointing: true, color: "blue" }}
        onTabChange={handleSelectTeam}
        panes={[
          ...teamPanes,
          {
            menuItem: {
              key: `MenuItem-teams`,
              content: (
                <div>
                  <Icon name="add circle" color="teal" style={{ cursor: "pointer" }} />
                </div>
              ),
            },
          },
        ]}
      />
    </div>
  );
};

/* ------------------------------------------------------ */

/*                   AssistantsElement;                   */

/* ------------------------------------------------------ */

const ProvisionDiagElement = (props: any) => {
  const handleChange = (index: number) => (e: any, v: any) => {
    props.onChangeValue?.(index, { ...v, field: props.field });
  };

  const handleSearch = (index: number) => (params: any) => {
    props.onSearch?.(index, { ...params, field: props.field });
  };

  const handleSelect = (index: number) => (data: any) => {
    props.onSelect?.(index, { ...data, field: props.field });
  };

  const handleClear = (index: number) => (e: any, v: any) => {
    props.onClear?.(index, { field: props.field });
  };

  const handleAdd = (index: number) => (e: any, v: any) => {
    props.onAdd?.(index, { field: props.field });
  };

  const handleDelete = (index: number) => (e: any, v: any) => {
    props.onDelete?.(index, { field: props.field });
  };

  // #console.log(props.items, props.icdType);
  return (
    <>
      {(props.items.length < props.defaultLength
        ? [
            ...props.items,
            ...Array(props.defaultLength - props.items.length).fill({}),
          ]
        : props.items
      ).map((item: any, index: number) => (
        <SubIcdDetail
          key={"sub-icd-procedure-" + index}
          item={item}
          primaryText="Principle Diagnosis"
          secondaryText="Secondary Diagnosis"
          icdType={props.icdType}
          icdKeyUpSearchList={item.table || []}
          showHeader={props.showHeader ? index === 0 : props.showHeader}
          hideActiveDisease={false}
          showSubType={props.showSubType}
          removable={props.removable ? index > 1 : props.removable}
          clearable={true}
          deletable={props.deletable ? index !== 0 : props.deletable}
          allowedAdd={index !== 0}
          addable={props.addable}
          boxStyle={{
            backgroundColor: "transparent",
            boxShadow: "none",
            border: "none",
            padding: 0,
          }}
          typeOptions={props.typeOptions}
          onChangeValue={props.isReadOnly ? () => {} : handleChange(index)}
          subType={item.subType}
          // config
          primaryTextStyle={{ textTransform: "unset" }}
          secondaryTextStyle={{ textTransform: "unset" }}
          requiredMedicalTerm={
            index === 0 && props.requiredMedicalTerm ? true : false
          }
          // callback
          onClear={props.isReadOnly ? () => {} : handleClear(index)}
          onAdd={props.isReadOnly ? () => {} : handleAdd(index)}
          onClickAdd={props.isReadOnly ? () => {} : handleAdd(index)}
          onDelete={props.isReadOnly ? () => {} : handleDelete(index)}
          onSearch={props.isReadOnly ? () => {} : handleSearch(index)}
          onSelect={props.isReadOnly ? () => {} : handleSelect(index)}
          onRemove={props.isReadOnly ? () => {} : handleDelete(index)}
          readOnly={props.isReadOnly}
        />
      ))}
    </>
  );
};

/* ------------------------------------------------------ */

/*                        MenuItem                        */

/* ------------------------------------------------------ */
export const MenuItem = (props: any) => {
  return (
    <div
      // className={`item${props.index === props.activeIndex ? " active" : ""}`}
      style={{
        cursor: "pointer",
        padding: 0,
        ...(props.index === props.activeIndex
          ? {
              color: props.color || "var(--primary-theme-color)",
              // borderBottom: `3px solid ${
              //   props.color || "var(--primary-theme-color)"
              // }`,
              fontWeight: "bold",
            }
          : {}),
      }}
    >
      <div>
        {props.isStar && <Icon name="star" color="yellow" />}
        <label>
          {props.title} {props.index + 1}
        </label>
      </div>
    </div>
  );
};

CardSurgeryTeam.displayName = "CardSurgeryTeam";

export default React.memo(CardSurgeryTeam);
